import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Container, Row, Col } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const SINGLE_ARTICLE_GALLERY_QUERY = gql`
    query singleArticleData($postId: ID!) {
        post(id: $postId) {
            is_featured
            status
            title
            uri
            frontline_articles_options {
                articleYear
                galleryPageDescription
            }
            id
            frontline_article_gallery_options {
                mediaType {
                    isFeatured
                    imageVideoDocumentTitle
                    imageVideoDocumentCaption
                    galleryVideoId
                    mediaType
                    galleryImage {
                        sourceUrl
                        id
                    }
                    documentUpload {
                        mediaItemUrl
                        id
                    }
                    galleryVideoPoster {
                        sourceUrl
                        id
                    }
                    documentPoster {
                        sourceUrl
                        id
                    }
                    galleryVideoType
                }
            }
        }
    }
`;

const SingleArticleGallery = ({ pageContext }) => {
	let postId = pageContext.id;

	const { data, loading, error } = useQuery(
		SINGLE_ARTICLE_GALLERY_QUERY,
		{ variables: { postId } }
	);

	useEffect(() => {
		// Article Video
		setTimeout(function () {
			if ($(".article-videoLink").length > 0) {
				$('.article-videoLink').click(function (e) {
					e.preventDefault();
					e.stopPropagation();
					var _videow = $(".embed-responsive").width();
					var _videoh = $(".embed-responsive").height();
					var video = '<div class="embed-responsive embed-responsive-16by9"><iframe id="modal-video" src="' + $(this).attr('data-video') + '" height="' + _videoh + '" width="' + _videow + '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe></div>';
					$(this).parent('.embed-responsive').replaceWith(video);
					return false;
				});
			}
		}, 2500);
	}, []);

	if (loading) return null;
	if (error) return <p>ERROR: {error.message}</p>;

	const articleGallery = data.post.frontline_article_gallery_options.mediaType.map((mediaTypes, index) => {
		return <div className="gallery_list_item" key={index}>
			{mediaTypes.mediaType === "image" && <Row>
				<Col className="gallery_list_item_title_sec" lg={{ span: 4 }}>
					{mediaTypes.imageVideoDocumentTitle && <h6>{mediaTypes.imageVideoDocumentTitle}</h6>}
					{mediaTypes.imageVideoDocumentCaption && <p>Photo Source: {mediaTypes.imageVideoDocumentCaption}</p>}
				</Col>
				<Col lg={{ span: 8 }}>
					<div className="text-center">
						{mediaTypes.galleryImage !== null && <img src={mediaTypes.galleryImage.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />}
					</div>
				</Col>
			</Row>}
			{mediaTypes.mediaType === "video" && <Row>
				<Col className="gallery_list_item_title_sec" lg={{ span: 4 }}>
					{mediaTypes.imageVideoDocumentTitle && <h6>{mediaTypes.imageVideoDocumentTitle}</h6>}
					{mediaTypes.imageVideoDocumentCaption && <p>Video Source: {mediaTypes.imageVideoDocumentCaption}</p>}
				</Col>
				<Col lg={{ span: 8 }}>
					{/* <img src={gallery_item.article_video_poster} alt={mediaTypes.imageVideoDocumentTitle} /> */}
					<div className="article_gallery_video_link_img">
						<div className="embed-responsive embed-responsive-16by9">
							{mediaTypes.galleryVideoPoster != null && <img className="img-fluid" src={mediaTypes.galleryVideoPoster.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />}
							{mediaTypes.galleryVideoType === "vimeo" && <a href="#!" className="article-videoLink" data-video={"https://player.vimeo.com/video/" + mediaTypes.galleryVideoId + "?autoplay=true"}>PLAY VIDEO</a>}
							{mediaTypes.galleryVideoType === "youtube" && <a href="#!" className="article-videoLink" data-video={"https://www.youtube.com/embed/" + mediaTypes.galleryVideoId + "?autoplay=true"}>PLAY VIDEO</a>}
						</div>
					</div>
				</Col>
			</Row>}
			{mediaTypes.mediaType === "document" && <Row>
				<Col className="gallery_list_item_title_sec" lg={{ span: 4 }}>
					{mediaTypes.imageVideoDocumentTitle && <h6>{mediaTypes.imageVideoDocumentTitle}</h6>}
					{mediaTypes.imageVideoDocumentCaption && <p>Document Source: {mediaTypes.imageVideoDocumentCaption}</p>}
				</Col>
				<Col lg={{ span: 8 }}>
					<div className="article-docLink_wrap">
						{mediaTypes.documentPoster !== null && <img src={mediaTypes.documentPoster.sourceUrl} alt={mediaTypes.imageVideoDocumentTitle} />}
						{mediaTypes.documentUpload !== null && <a href={mediaTypes.documentUpload.mediaItemUrl} target="_blank" className="article-docLink" rel="noopener noreferrer"><span>DOWNLOAD PDF</span></a>}
					</div>
				</Col>
			</Row>}
		</div>
	});

	return (
		<Layout>
			<SEO title={data.post.title} />
			<div className="article_gallery_page">
				<div className="gallery_desc_sec">
					<Container>
						<Row>
							<Col className="order-1 order-sm-0" sm={{ span: 4 }} xl={{ span: 3 }}>
								<h2 className="gallery_desc_title">GALLERY</h2>
							</Col>
							<Col className="text-sm-right order-0 order-sm-1" sm={{ span: 8 }} xl={{ span: 9 }}>
								{data.post.uri && <AniLink className="back-to-article-btn" fade duration={2} to={"/" + data.post.uri}>BACK TO ARTICLE <span className="close-btn">X</span></AniLink>}
							</Col>
						</Row>
						<Row>
							<Col lg={{ span: 10 }} xl={{ span: 9 }}>
								{data.post.title && <h1 className="page-title">{data.post.frontline_articles_options.articleYear}: {data.post.title}</h1>}
								{data.post.frontline_articles_options.galleryPageDescription && <div className="gallery_desc" dangerouslySetInnerHTML={{ __html: data.post.frontline_articles_options.galleryPageDescription }} />}
							</Col>
						</Row>
					</Container>
				</div>
				<div className="gallery_list">
					<Container>
						{articleGallery}
					</Container>
				</div>
			</div>
		</Layout>
	)
}

export default SingleArticleGallery
